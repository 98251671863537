import { requester } from '../../utils/requester';
import crypto from 'crypto';
import {
  ENTRY_CREATE,
  // ENTRY_DELETE,
  ENTRY_ERROR,
  ENTRY_LOAD,
  ENTRY_LOAD_ALL,
  ENTRY_UPDATE,
  ENTRY_UPDATE_NOTIFY
} from '../types';

const genHash = str => crypto.createHash('sha1').update(str).digest('hex');

export const createEntry = (notebookId = 'default', entry = {}) => async dispatch => {
  try {
    const res = await requester.post(`/api/notebooks/${notebookId}/entries`, entry);

    if (!res.data.success) throw new Error(res.data.error);

    const payload = res.data.entry;

    dispatch({ type: ENTRY_CREATE, payload });

  } catch (err) {
    dispatch({ type: ENTRY_ERROR });
  }
};

export const getEntries = (notebookId = 'default') => async dispatch => {
  try {
    const res = await requester.get(`/api/notebooks/${notebookId}/entries`);

    if (!res.data.success) throw new Error(res.data.error);

    const payload = res.data.entries;

    dispatch({ type: ENTRY_LOAD_ALL, payload });

  } catch (err) {
    dispatch({ type: ENTRY_ERROR });
  }
};

export const getEntry = (entryId, notebookId = 'default') => async dispatch => {
  try {
    const res = await requester.get(`/api/notebooks/${notebookId}/entries/${entryId}`);

    if (!res.data.success) throw new Error(res.data.error);

    const payload = res.data.entry;

    dispatch({ type: ENTRY_LOAD, payload });

  } catch (err) {
    dispatch({ type: ENTRY_ERROR });
  }
};

export const updateEntry = (entryId, updates, notebookId = 'default') => async dispatch => {
  try {
    const res = await requester.post(`/api/notebooks/${notebookId}/entries/${entryId}`, updates);

    if (!res.data.success) throw new Error(res.data.error);

    const payload = res.data.entry;

    dispatch({ type: ENTRY_UPDATE, payload });
    dispatch({ type: ENTRY_UPDATE_NOTIFY, payload: genHash(payload.draftjs_data) });

  } catch (err) {
    dispatch({ type: ENTRY_ERROR });
  }
};

// NTH: Be able to delete an entry once API has that functionality
// export const deleteEntry = () => async dispatch => {};
