import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { checkPasswordResetToken, resetPassword } from '../../state/actions/auth';
import { WSFormLabel, WSFormSubmitButton } from '../layout/Utils';

const ResetPassword = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = 'Reset Password';
  }, []);

  // Get query params
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');
  const id = query.get('id');
  const t = query.get('t');
  const { isTokenValid, passwordUpdated } = useSelector(state => state.auth);
  const from = { pathname: '/' };
  const history = useHistory();

  const resetSchema = yup.object({
    password: yup.string().required('Password is required'),
    passwordConfirm: yup.string().test('passwords-match', 'Passwords must match', (value, context) => (
      value === context.parent.password
    )),
  });

  const handleOnSubmit = async values => {
    dispatch(resetPassword(values.password, { token, id, t }));
  };

  useEffect(() => {
    dispatch(checkPasswordResetToken(token, id, t));
  }, [ token, id, t ]);

  useEffect(() => {
    if (passwordUpdated) history.replace(from);
  }, [ passwordUpdated ]);

  return isTokenValid ? <>
      <Formik
      initialValues={{ password: '', passwordConfirm: '' }}
      validationSchema={resetSchema}
      validateOnChange={false}
      onSubmit={handleOnSubmit}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
              <Form.Group as={Col} xs={12} md={6} lg={4} xl={3} className="mb-4">
              <WSFormLabel>
                <FontAwesomeIcon icon={faAsterisk} /> Password
              </WSFormLabel>
              <Form.Control type="password" name="password" value={values.password} onChange={handleChange} />
              {errors.password && touched.password && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} xs={12} md={6} lg={4} xl={3} className="mb-4">
              <WSFormLabel>
                <FontAwesomeIcon icon={faAsterisk} /> Confirm Password
              </WSFormLabel>
              <Form.Control type="password" name="passwordConfirm" value={values.passwordConfirm} onChange={handleChange} />
              {errors.passwordConfirm && touched.passwordConfirm && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.passwordConfirm}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group>
              <WSFormSubmitButton>
                Reset Password
              </WSFormSubmitButton>
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  </> : <>
      <h1>Reset Password</h1>
      <p>Invalid token</p>
  </>;
}


export default ResetPassword;