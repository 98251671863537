import React from 'react';
import { Link } from 'react-router-dom';
import './styles/footer.scss';

const Footer = () => (
  <footer className="py-5 bg-primary ws-footer">
    <div className="d-flex justify-content-center align-items-center">
      <div>
        <p className="mb-0 text-center">Writer<b>Streak</b></p>
        <p>
          <Link to="/privacy-policy" className="text-reset">Privacy Policy</Link>
          {' | '}
          <Link to="/terms-of-service" className="text-reset">Terms of Service</Link>
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
