import React from 'react';
import { Route, Switch } from 'react-router-dom';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';
import useGoogleTagManager from '../../hooks/useGoogleTagManager';
import useUniversalAnalytics from '../../hooks/useUniversalAnalytics';
import ForgotPassword from '../auth/ForgotPassword';
import Login from '../auth/Login';
import Profile from '../auth/Profile';
import Register from '../auth/Register';
import ResetPassword from '../auth/ResetPassword';
import Billing from '../billing/Billing';
import Entry from '../entry/Entry';
import ManageEntries from '../entry/ManageEntries';
import FAQ from '../faq/FAQ';
import Home from '../home/Home';
import Loading from '../layout/Loading';
import PageNotFound from '../layout/PageNotFound';
import PrivacyPolicy from '../legal/PrivacyPolicy';
import TermsOfService from '../legal/TermsOfService';
// import ManageNotebooks from '../notebook/ManageNotebooks';
import Notebook from '../notebook/Notebook';
import Private from './Private';

const Routes = () => {
  const loadingGA = useGoogleAnalytics();

  useGoogleTagManager();
  useUniversalAnalytics();

  return loadingGA ? <Loading /> : <>
    <Switch>
      <Route exact path="/"><Home /></Route>
      <Route exact path="/register"><Register /></Route>
      <Route exact path="/login"><Login /></Route>
      <Route exact path="/forgot-password"><ForgotPassword /></Route>
      <Route exact path="/reset-password"><ResetPassword /></Route>
      <Route exact path="/faq"><FAQ /></Route>
      <Route exact path="/privacy-policy"><PrivacyPolicy /></Route>
      <Route exact path="/terms-of-service"><TermsOfService /></Route>
      <Private exact path="/billing"><Billing /></Private>
      <Private exact path="/profile"><Profile /></Private>
      <Private exact path="/goal"><Notebook /></Private>
      <Private exact path="/entries"><ManageEntries /></Private>
      <Private exact path="/entries/:id"><Entry /></Private>
      <Route path="*" component={PageNotFound} />
    </Switch>
  </>;
};

export default Routes;
