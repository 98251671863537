import { useState, useEffect } from 'react';

export function gtag () { window.dataLayer.push(arguments) }

const useGoogleAnalytics = () => {
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    const { REACT_APP_GA_MEASUREMENT_ID: measurementId } = process.env;
    const script = document.createElement('script');
    const timeout = setTimeout(() => { setLoading(false) }, 200);

    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    script.async = true;
    document.head.appendChild(script);
    script.onload = () => { setLoading(false) };

    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    gtag('config', measurementId);

    return () => {
      document.head.removeChild(script);
      clearTimeout(timeout);
    };
  }, []);

  return loading;
};

export default useGoogleAnalytics;
