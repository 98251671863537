import { GLOBAL_ALERT_CREATE, GLOBAL_ALERT_DELETE } from '../types';

const initialState = [];

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GLOBAL_ALERT_CREATE:
      return [ ...state, payload ];

    case GLOBAL_ALERT_DELETE:
      return state.filter(globalAlert => globalAlert.id !== payload);

    default:
      return state;
  }
};
