import {
  AUTH_ERROR,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_PASSWORD_RESET_TOKEN_CHECKED,
  AUTH_PASSWORD_UPDATE_SUCCESS,
  AUTH_REGISTER_ERROR,
  AUTH_REGISTER,
  AUTH_USER_LOAD,
  AUTH_USER_UPDATE,
  AUTH_USER_UPDATE_ERROR,
} from '../types';
import { getToken } from '../../utils/manageToken';

const preloadedState = {
  loading: true,
  token: getToken(),
  isLoggedIn: null,
  isPaid: null,
  isStripePaid: null,
  isTokenValid: null,
  role: null,
  email: null,
  username: null,
  timezone: null,
  passwordUpdated: null,
};

export default (state = preloadedState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH_USER_LOAD:
    case AUTH_USER_UPDATE:
    case AUTH_LOGIN:
    case AUTH_REGISTER:
      return {
        ...state,
        ...payload,
        isLoggedIn: true,
        loading: false,
        passwordUpdated: false,
      };

    case AUTH_LOGIN_ERROR:
    case AUTH_REGISTER_ERROR:
      return {
        ...preloadedState,
        loading: false,
        passwordUpdated: false,
      };

    case AUTH_LOGOUT:
      return {
        ...preloadedState,
        token: null,
        loading: false,
        passwordUpdated: false,
      };

    case AUTH_USER_UPDATE_ERROR:
    case AUTH_ERROR:
      return {
        ...state,
        loading: false,
        passwordUpdated: false,
      };

    case AUTH_PASSWORD_RESET_TOKEN_CHECKED:
      return {
        ...state,
        isTokenValid: payload,
        loading: false,
        passwordUpdated: false,
      };
    
    case AUTH_PASSWORD_UPDATE_SUCCESS:
      return {
        ...preloadedState,
        token: null,
        loading: false,
        passwordUpdated: true,
      };
    default:
      return state;
  }
};
