import { requester } from '../../utils/requester';
import {
  NOTEBOOK_CREATE,
  // NOTEBOOK_DELETE,
  NOTEBOOK_ERROR,
  NOTEBOOK_LOAD,
  // NOTEBOOK_LOAD_ALL,
  NOTEBOOK_UPDATE
} from '../types';
import { createGlobalAlert } from './globalAlerts';

export const createNotebook = notebook => async dispatch => {
  try {
    const res = await requester.post('/api/notebooks', notebook);

    if (!res.data.success) throw new Error(res.data.error);

    const payload = res.data.notebook;

    dispatch({ type: NOTEBOOK_CREATE, payload });
    dispatch({ type: NOTEBOOK_LOAD, payload });

  } catch (err) {
    dispatch({ type: NOTEBOOK_ERROR });
    dispatch(createGlobalAlert('Goal Error', 'Failed to save goal. Please try again.', 'danger'));
  }
};

// NTH: Create an action to pull all notebooks once that capability is available
// export const getNotebooks = () => async dispatch => {};

export const getNotebook = notebookId => async dispatch => {
  try {
    const res = await requester.get(`/api/notebooks/${notebookId}`);

    if (!res.data.success) throw new Error(res.data.error);

    const payload = res.data.notebook;

    dispatch({ type: NOTEBOOK_LOAD, payload });

  } catch (err) {
    dispatch({ type: NOTEBOOK_ERROR });
  }
};

export const updateNotebook = (notebookId, updates) => async dispatch => {
  try {
    const res = await requester.post(`/api/notebooks/${notebookId}`, updates);

    if (!res.data.success) throw new Error(res.data.error);

    const payload = res.data.notebook;

    dispatch({ type: NOTEBOOK_UPDATE, payload });
    dispatch({ type: NOTEBOOK_LOAD, payload });

  } catch (err) {
    dispatch({ type: NOTEBOOK_ERROR });
    dispatch(createGlobalAlert('Goal Error', 'Failed to save goal. Please try again.', 'danger'));
  }
};

// NTH: Be able to delete a notebook once API has that functionality
// export const deleteNotebook = notebookId => async dispatch => {};
