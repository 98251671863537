// Shows 'Forgot Password' button
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { WSFormLabel, WSFormSubmitButton } from '../layout/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { forgotPassword } from '../../state/actions/auth';

const ForgotPassword = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Forgot Password';
  }, []);

  const handleOnSubmit = values => {
    dispatch(forgotPassword(values));
  };

  return <>
    <h1>Forgot Password</h1>

    <Formik
      initialValues={{ email: '' }}
      onSubmit={handleOnSubmit}
    >
      {({ values, errors, handleChange, handleSubmit, status }) => (
        <Form onSubmit={handleSubmit} noValidate>
          <Row>
            <Form.Group as={Col} xs={12} md={6} lg={4} xl={3} className="mb-4">
              <WSFormLabel>
                <FontAwesomeIcon icon={faEnvelope} /> Enter Your Email
              </WSFormLabel>
              <Form.Control type="text" name="email" value={values.email} onChange={handleChange} />
              {errors.email && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Row>
          <Form.Group>
            <WSFormSubmitButton>
              Submit
            </WSFormSubmitButton>
          </Form.Group>
          {status && status.msg && (
            <Alert variant="success">{status.msg}</Alert>
          )}
        </Form>
      )}
    </Formik>
  </>;
}

export default ForgotPassword;
