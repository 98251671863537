import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotebook } from '../../state/actions/notebook';
import { getEntries, createEntry } from '../../state/actions/entry';
import Loading from '../layout/Loading';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faEye, faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { some, isEmpty } from 'lodash';
import './styles/manage-entries.scss';

const ManageEntries = () => {
  const dispatch = useDispatch();
  const {
    loading: loadingNotebook,
    notebook
  } = useSelector(state => state.notebook);
  const {
    loading: loadingEntry,
    entries
  } = useSelector(state => state.entry);

  useEffect(() => {
    dispatch(getNotebook('default'));
    dispatch(getEntries());
  }, []);

  const canCreateEntry = () => !some(entries, e => e.isToday);

  const handleCreateEntry = () => { dispatch(createEntry('default')) };

  return <>
    <h1>Your Entries</h1>

    {loadingNotebook || loadingEntry ? <Loading /> : <>
      {notebook ? <>
        <h3 className="mb-0"><strong>Current Streak</strong>: {notebook.current_streak}</h3>
        <h5 className="mb-2"><strong>Your best streak</strong>: {notebook.max_streak}</h5>
        <h3 className="mt-2">This week, you&apos;ve hit your goal <strong>{notebook.this_week_streak} of {notebook.days_per_week_goal} days</strong>.</h3>

        {canCreateEntry() && (
          <Button onClick={() => handleCreateEntry()} className="mb-4">
            <FontAwesomeIcon icon={faPlus} /> Add Entry for Today
          </Button>
        )}

        {!isEmpty(entries) ? <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Word Count</th>
                <th>Goal Achieved</th>
              </tr>
            </thead>
            <tbody>
              {entries.map(entry => (
                <tr key={entry.id}>
                  <td className="td-entry">
                    <Link to={`/entries/${entry.id}`}>
                      <Button>
                        {entry.isToday ? <>
                          <FontAwesomeIcon icon={faEdit} /> Edit
                        </> : <>
                          <FontAwesomeIcon icon={faEye} /> View
                        </>}
                      </Button>
                    </Link>
                  </td>
                  <td className="td-entry">
                    { format(new Date(entry.createdAt), 'MMM d y')}
                  </td>
                  <td className="td-entry">
                    {entry.word_count} / {notebook.word_goal}
                  </td>
                  <td className="td-entry">
                    {entry.goal_achieved ?
                      <FontAwesomeIcon icon={faCheck} />
                      :
                      <FontAwesomeIcon icon={faMinus} />
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </> : <p>You currently don&#39;t have any entries.</p>}
      </> : <p>Please set a goal <Link to="/goal">here</Link> first.</p>}
    </>}
  </>;
};

export default ManageEntries;
