import {
  ENTRY_CREATE,
  ENTRY_DELETE,
  ENTRY_ERROR,
  ENTRY_LOAD,
  ENTRY_LOAD_ALL,
  ENTRY_UPDATE,
  ENTRY_UPDATE_NOTIFY
} from '../types';

const preloadedState = {
  loading: true,
  entries: [],
  entry: null,
  contentHash: ''
};

export default (state = preloadedState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ENTRY_LOAD_ALL:
      return {
        ...state,
        entries: payload,
        loading: false
      };

    case ENTRY_LOAD:
      return {
        ...state,
        entry: payload,
        loading: false
      };

    case ENTRY_CREATE:
      return {
        ...state,
        entries: [ payload, ...state.entries ],
        loading: false
      };

    case ENTRY_UPDATE:
      return {
        ...state,
        entries: state.entries.map(e => e.id === payload.id ? { ...e, ...payload } : e),
        entry: state.entry.id === payload.id ? { ...state.entry, ...payload } : { ...state.entry },
        loading: false
      };

    case ENTRY_UPDATE_NOTIFY:
      return {
        ...state,
        contentHash: payload
      };

    case ENTRY_DELETE:
      return {
        ...state,
        entries: state.entries.filter(e => e.id !== payload),
        entry: null,
        loading: false
      };

    case ENTRY_ERROR:
      return {
        ...state,
        loading: false

      };

    default:
      return state;
  }
};