import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { initialize, event, set, pageview } from 'react-ga';

export const sendEvent = payload => { event(payload) };

export const sendPageView = page => {
  set({ page });
  pageview(page);
};

const useUniversalAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const {
      REACT_APP_GA_TRACKING_ID: trackingId,
      REACT_APP_GA_DEBUG: isDebug,
      NODE_ENV: nodeEnv
    } = process.env;
    const debug = isDebug === 'true' && nodeEnv && nodeEnv === 'development';

    initialize(trackingId, { debug });
  }, []);

  useEffect(() => { sendPageView(`${location.pathname}${location.search}`) }, [ location ]);
};

export default useUniversalAnalytics;
