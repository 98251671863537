import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../state/actions/auth';
import { getNotebook } from '../../state/actions/notebook';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const Navigation = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, isPaid } = useSelector(store => store.auth);
  const {
    notebook
  } = useSelector(store => store.notebook);

  useEffect(() => {
    dispatch(getNotebook('default'));
  }, []);

  const handleOnClickLogOut = e => {
    e.preventDefault();
    dispatch(logout());
  };

  return <>
    <Navbar className="px-5 bg-primary d-flex justify-content-between" variant="dark">
      <Nav className="align-items-center">
        <Navbar.Brand as={Link} to="/">
          <h1 className="mb-0">Writer<b>Streak</b></h1>
        </Navbar.Brand>
        {isLoggedIn && <>
          {isPaid ? <>
            <Nav.Link as={Link} to="/goal">
              Goal
            </Nav.Link>
            {notebook && <>
              <Nav.Link as={Link} to="/entries">
                Entries
              </Nav.Link>
            </>}
          </> : <>
            <Nav.Link as={Link} to="/billing">
              Get Premium
            </Nav.Link>
          </>}
        </>}
        <Nav.Link as={Link} to="/faq">
          FAQ
        </Nav.Link>
      </Nav>

      <Nav className="align-items-center">
        {isLoggedIn ? <>
          <NavDropdown title={<FontAwesomeIcon icon={faUser} />} id="collasible-nav-dropdown">
            <NavDropdown.Item as={Link} to="/profile">
              Profile
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={e => handleOnClickLogOut(e)}>
              Log Out
            </NavDropdown.Item>
          </NavDropdown>
        </> : <>
          <Nav.Link as={Link} to="/login">
            Login
          </Nav.Link>
          <Nav.Link as={Link} to="/register">
            Register
          </Nav.Link>
        </>}
      </Nav>
    </Navbar>
  </>;
};

export default Navigation;
