import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from './reducers';
import { setToken } from '../utils/manageToken';
import { setHeaderToken } from '../utils/requester';

const preloadedState = {};
const middleware = [ thunk ];

const store = createStore(
  rootReducer,
  preloadedState,
  composeWithDevTools(applyMiddleware(...middleware))
);

let stateCurr = store.getState();

store.subscribe(() => {
  let statePrev = stateCurr;

  stateCurr = store.getState();

  if (statePrev.auth.token !== stateCurr.auth.token) {
    const token = stateCurr.auth.token;

    setToken(token);
    setHeaderToken(token);
  }
});

export default store;
