import { requester, clearHeaderToken } from '../../utils/requester';
import {
  AUTH_ERROR,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_PASSWORD_UPDATE_SUCCESS,
  AUTH_REGISTER_ERROR,
  AUTH_REGISTER,
  AUTH_USER_LOAD,
  AUTH_USER_UPDATE,
  AUTH_USER_UPDATE_ERROR,
  AUTH_PASSWORD_RESET_TOKEN_CHECKED,
} from '../types';
import { createGlobalAlert } from './globalAlerts';
import { clearToken } from '../../utils/manageToken';

export const register = credentials => async dispatch => {
  try {
    const res = await requester.post('/api/users/register', credentials);

    dispatch({ type: AUTH_REGISTER, payload: res.data });

  } catch (err) {
    dispatch({ type: AUTH_REGISTER_ERROR });
    dispatch(createGlobalAlert('Registration', 'Failed to register', 'danger'));
  }
};

export const login = (credentials, recaptchaToken) => async dispatch => {
  // Add recaptchaToken to the credentials
  credentials.recaptchaToken = recaptchaToken;
  try {
    const res = await requester.post('/api/users/login', credentials);

    dispatch({ type: AUTH_LOGIN, payload: res.data });

  } catch (err) {
    dispatch({ type: AUTH_LOGIN_ERROR });
    dispatch(createGlobalAlert('Login', 'Failed to log in', 'danger'));
  }
};

export const logout = () => async dispatch => {
  clearToken();
  clearHeaderToken();
  dispatch({ type: AUTH_LOGOUT });
};

export const forgotPassword = email => async dispatch => {
  try {
    const res = await requester.post('/api/users/forgot-password', { email });
    if (res.data.success) {
      dispatch(createGlobalAlert('Forgot Password', 'Email sent', 'success'));
    }
  } catch (err) {
    dispatch(createGlobalAlert('Forgot Password', 'Failed to send email', 'danger'));
  }
};

export const resetPassword = (password, { token, id, t, originalPassword }) => async dispatch => {
  try {
    const endpoint = originalPassword ? 'update-user-password' : 'update-password';
    const res = await requester.post(`/api/users/${endpoint}`, { 
      token,
      id,
      t,
      password,
      originalPassword,
    });
    if (res.data.success) {
      dispatch(createGlobalAlert('Reset Password', 'Password changed', 'success'));
      dispatch({ type: AUTH_PASSWORD_UPDATE_SUCCESS });
    } else {
      dispatch(createGlobalAlert('Reset Password', 'Failed to change password', 'danger'));
    }
  } catch (err) {
    dispatch(createGlobalAlert('Reset Password', 'Failed to reset password', 'danger'));
  }
};

export const checkPasswordResetToken = (token, id, t) => async dispatch => {
  try {
    const res = await requester.post('/api/users/check-password-reset-token', { token, id, t });
    const isPasswordTokenValid = res.data.success;
    dispatch({ type: AUTH_PASSWORD_RESET_TOKEN_CHECKED, payload: isPasswordTokenValid });
  } catch (err) {
    dispatch(createGlobalAlert('Reset Password', 'Failed to check token', 'danger'));
  }
};

export const loadUser = () => async dispatch => {
  try {
    const res = await requester.get('/api/users/jwt');

    dispatch({ type: AUTH_USER_LOAD, payload: res.data });

  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};

export const updateUser = user => async dispatch => {
  try {
    const res = await requester.post('/api/users/jwt', user);

    dispatch({ type: AUTH_USER_UPDATE, payload: res.data });

  } catch (err) {
    dispatch({ type: AUTH_USER_UPDATE_ERROR });
    dispatch(createGlobalAlert('Update', 'Failed to update user information. Please try again.', 'danger'));
  }
};

export const checkSessionIsPaid = sessionId => async dispatch => {
  try {
    const res = await requester.post('/api/purchases/session', { session_id: sessionId });
    const isPaid = res.data.payment_status === 'paid' || false;

    dispatch({ type: AUTH_USER_UPDATE, payload: { isPaid } });

  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};
