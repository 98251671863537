import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import './styles/faq.scss';

const FAQ = () => (
  <>
    <h1>Frequently Asked Questions</h1>
    <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey={0}>
        <Accordion.Header>
          What is WriterStreak?
        </Accordion.Header>
        <Accordion.Body>
          <p>WriterStreak is a tool to help you build your writing habit. Set a word goal, a frequency goal, and we&apos;ll track your goal streak!</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey={1}>
        <Accordion.Header>
          How do I use WriterStreak?
        </Accordion.Header>
        <Accordion.Body>
          <p>WriterStreak is meant to be used primarily as a tool for churning out word count. Too often, writers will get caught up in the details / editing and never finish what they set out to do.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey={2}>
        <Accordion.Header>
          How do I get started?
        </Accordion.Header>
        <Accordion.Body>
          <p>Sign up for an account.</p>
          <p>Sign up for a free trial.</p>
          <p>Set a goal</p>
          <p>Start writing!</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey={3}>
        <Accordion.Header>
          Why do you need my timezone?
        </Accordion.Header>
        <Accordion.Body>
          <p>We need to know your timezone so we can make sure your streak is accurate. It&apos;s what we use to determine which day to attribute your current draft toward.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey={4}>
        <Accordion.Header>
          What happens if I start a draft right before midnight?
        </Accordion.Header>
        <Accordion.Body>
          <p>The day that you start the draft is the day that the goal will be attributed to. If you leave the page and return later, the draft will be uneditable so be sure to try to reach your goal count quickly!</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey={5}>
        <Accordion.Header>
          What if I need to change my goal? How does that affect my streak?
        </Accordion.Header>
        <Accordion.Body>
          <p>Streaks are locked in every week. If you edit the streak for the current week going forward, you can do so without affecting your existing streak.</p>
          <p>For example, if it&apos;s Saturday and you&apos;ve only had one draft reach your word count, but your goal is to hit your word count for five days in a week, you could maintain your streak (and be a no-good cheater) by editing your goal before Sunday rolls around.</p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </>
);

export default FAQ;
