import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { getNotebook } from '../../state/actions/notebook';
import { getEntry, updateEntry } from '../../state/actions/entry';
import { format } from 'date-fns';
import Loading from '../layout/Loading';
import Form from 'react-bootstrap/Form';
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw
} from 'draft-js';
import { clearEditorContent } from 'draftjs-utils';
import 'draft-js/dist/Draft.css';
import './styles/editor.scss';

const Entry = () => {
  const inlineStyles = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Monospace', style: 'CODE' }
  ];
  const dispatch = useDispatch();
  const debouncedAutoSave = useDebouncedCallback((entryId, updates, notebookId) => {
    dispatch(updateEntry(entryId, updates, notebookId));
  }, 2000, { maxWait: 5000 });
  const { id: entryId } = useParams();
  const { loading: loadingNotebook, notebook } = useSelector(state => state.notebook);
  const {
    loading: loadingEntry,
    entry,
    contentHash
  } = useSelector(state => state.entry);
  const [ editorState, setEditorState ] = useState(() => EditorState.createEmpty());
  const [ wordCount, setWordCount ] = useState(0);
  const [ showSavedMsg, setShowSavedMsg ] = useState(false);
  const isReadOnly = loadingEntry || entry && !entry.isToday;

  useEffect(() => { dispatch(getNotebook('default')) }, []);

  useEffect(() => { dispatch(getEntry(entryId, 'default')) }, [ entryId ]);

  useEffect(() => {
    if (!loadingEntry) {
      if (entry && entry.draftjs_data) {
        const content = convertFromRaw(JSON.parse(entry.draftjs_data));
        const preloadedState = EditorState.createWithContent(content);
        const initWordCount = getWordCount(preloadedState);

        setEditorState(preloadedState);
        setWordCount(initWordCount);

      } else {
        setEditorState(clearEditorContent(editorState));
      }
    }
  }, [ loadingEntry, entry?.id ]);

  useEffect(() => {
    let isMounted = true;

    if (contentHash !== '') {
      setShowSavedMsg(true);
      setTimeout(() => {
        if (isMounted) setShowSavedMsg(false);
      }, 3000);
    }

    return () => { isMounted = false };
  }, [ contentHash ]);

  const getWordCount = state => {
    const plainText = state.getCurrentContent().getPlainText('');
    const cleanString = plainText.replace(/(?:\r\n|\r|\n)/g, ' ').trim();
    const words = cleanString.match(/\S+/g);

    return words ? words.length : 0;
  };

  const handleOnChange = state => {
    const newWordCount = getWordCount(state);

    setEditorState(state);
    setWordCount(newWordCount);
    debouncedAutoSave(
      entryId,
      {
        word_count: newWordCount,
        draftjs_data: JSON.stringify(convertToRaw(state.getCurrentContent()))
      },
      'default'
    );
  };

  const handleOnBlur = e => {
    e.preventDefault();
    debouncedAutoSave(
      entryId,
      {
        word_count: wordCount,
        draftjs_data: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      },
      'default'
    );
  };

  const handleKeyCommand = (command, state) => {
    const newState = RichUtils.handleKeyCommand(state, command);

    if (newState) {
      handleOnChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const handleToggleInlineStyle = (e, inlineStyle) => {
    e.preventDefault();

    const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle);

    handleOnChange(newState);
  };

  const Info = () => (
    <p>
      <span>{wordCount}/{notebook.word_goal}</span>
      {' '}
      <span>{showSavedMsg && 'Saved'}</span>
    </p>
  );

  const StyleButton = ({ label, style }) => {
    const isApplied = editorState.getCurrentInlineStyle().has(style);

    return <>
      <span onMouseDown={e => handleToggleInlineStyle(e, style)}
        className={`me-2 editor-style-button ${isApplied && ' style-applied'}`}
      >
        {label}
      </span>
    </>;
  };

  StyleButton.propTypes = {
    label: PropTypes.string.isRequired,
    style: PropTypes.string.isRequired
  };

  return loadingNotebook || loadingEntry ? <Loading /> : <>
    {notebook && entry && <>
      <h1>Entry on {format(new Date(entry.createdAt), 'MMMM do y')}</h1>

      <Form noValidate>
        <Info />

        <div className={`editor-wrapper${isReadOnly ? ' editor-read-only' : ''}`}>
          {!isReadOnly && <>
            <div className="editor-controls">
              {inlineStyles.map(s => <StyleButton key={s.label} label={s.label} style={s.style} />)}
            </div>
          </>}

          <div className="editor-body">
            <Editor
              readOnly={isReadOnly}
              editorState={editorState}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              handleKeyCommand={handleKeyCommand}
              placeholder='Start writing...'
            />
          </div>
        </div>

        <Info />

      </Form>
    </>}
  </>;
};

export default Entry;
