import { useEffect } from 'react';

const useGoogleTagManager = () => {

  useEffect(() => {
    const { REACT_APP_GTM_CONTAINER_ID: gtmContainerId } = process.env;
    const script = document.createElement('script');
    const noscript = document.createElement('noscript');

    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmContainerId}');
    `;
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmContainerId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.head.insertBefore(script, document.head.childNodes[0]);
    document.body.insertBefore(noscript, document.body.childNodes[0]);
  }, []);
};

export default useGoogleTagManager;
