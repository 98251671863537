import { combineReducers } from 'redux';
import auth from './auth';
import entry from './entry';
import globalAlerts from './globalAlerts';
import notebook from './notebook';

const rootReducer = combineReducers({
  auth,
  entry,
  globalAlerts,
  notebook
});

export default rootReducer;
