import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const WSFormLabel = ({ children }) => (
  <Form.Label className="px-2">
    {children}
  </Form.Label>
);

WSFormLabel.propTypes = { children: PropTypes.node };

const WSFormSubmitButton = ({ children }) => (
  <Button type="submit" className="mb-3" variant="primary" size="large" block="true">
    {children}
  </Button>
);

WSFormSubmitButton.propTypes = { children: PropTypes.node };

export {
  WSFormLabel,
  WSFormSubmitButton
};
