import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import { gtag } from '../../hooks/useGoogleAnalytics';
import { sendEvent } from '../../hooks/useUniversalAnalytics';
import { register } from '../../state/actions/auth';
import { Formik } from 'formik';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { WSFormLabel, WSFormSubmitButton } from '../layout/Utils';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faAsterisk, faClock, faTags } from '@fortawesome/free-solid-svg-icons';
import timezones from './data/timezones';

const Register = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(state => state.auth);
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const promo = query.get('promo');
  const { from } = location.state || { from: { pathname: '/' } };
  const registerSchema = yup.object({
    email: yup.string().email().required('Email is required'),
    password: yup.string().min(6).required('Password is required'),
    passwordConfirm: yup.string().test('passwords-match', 'Passwords must match', (value, context) => (
      value === context.parent.password
    )),
    timezone: yup.string().oneOf(timezones).required('Timezone required'),
    promo: yup.string()
  });

  useEffect(() => { if (isLoggedIn) history.replace(from) }, [ isLoggedIn ]);

  const handleOnSubmit = values => {
    dispatch(register(values));
    gtag('event', 'sign_up');
    sendEvent({ category: 'user', action: 'sign up' });
    history.push('/billing');
  };

  return <>
    <h1>Register</h1>

    <Formik
      initialValues={{
        email: '',
        password: '',
        passwordConfirm: '',
        timezone: 'America/Chicago',
        promo: promo || ''
      }}
      validationSchema={registerSchema}
      validateOnChange={false}
      onSubmit={handleOnSubmit}
    >
      {({ values, errors, handleChange, handleSubmit, status }) => <>
        <Form onSubmit={handleSubmit} noValidate>
          <Row>
            <Form.Group as={Col} xs={12} lg={4} xl={3} className="mb-4">
              <WSFormLabel>
                <FontAwesomeIcon icon={faEnvelope} /> Email
              </WSFormLabel>
              <Form.Control type="text" name="email" value={values.email} onChange={handleChange}/>
              {errors.email && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} lg={4} xl={3} className="mb-4">
              <WSFormLabel>
                <FontAwesomeIcon icon={faAsterisk} /> Password
              </WSFormLabel>
              <Form.Control type="password" name="password" value={values.password} onChange={handleChange} />
              {errors.password && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} lg={4} xl={3} className="mb-4">
              <WSFormLabel>
                <FontAwesomeIcon icon={faAsterisk} /> Confirm Password
              </WSFormLabel>
              <Form.Control type="password" name="passwordConfirm" value={values.passwordConfirm}
                onChange={handleChange}
              />
              {errors.passwordConfirm && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.passwordConfirm}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} xl={3} className="mb-4">
              <WSFormLabel>
                <FontAwesomeIcon icon={faClock} /> Timezone
              </WSFormLabel>
              <Form.Control as={Form.Select} name="timezone" value={values.timezone} onChange={handleChange}>
                {timezones.map(t => (
                  <option key={t} value={t}>{t}</option>
                ))}
              </Form.Control>
              {errors.timezone && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.timezone}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            {promo && (
              <Form.Group as={Col} xs={12} md={6} xl={3} className="mb-4">
                <WSFormLabel>
                  <FontAwesomeIcon icon={faTags} /> Promo
                </WSFormLabel>
                <Form.Control type="text" name="promo" value={values.promo} onChange={handleChange}/>
                {errors.promo && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.promo}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}
          </Row>

          <Form.Group>
            <WSFormSubmitButton>
              Register
            </WSFormSubmitButton>
          </Form.Group>

          {status && status.msg && (
            <Alert variant="success">{status.msg}</Alert>
          )}
        </Form>
      </>}
    </Formik>

    <p className="mt-2">
      Already have an account? <Link to="/login">Log in here.</Link>
    </p>
  </>;
};

export default Register;
