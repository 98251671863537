export const setToken = token => { localStorage.setItem('token', token) };

export const getToken = () => {
  try {
    return localStorage.getItem('token');

  } catch (err) {
    return null;
  }
};

export const clearToken = () => { localStorage.removeItem('token') };
