import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './state/store';
import { loadUser, logout } from './state/actions/auth';
import { getToken } from './utils/manageToken';
import { setHeaderToken } from './utils/requester';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/layout/Header';
import Main from './components/layout/Main';
import Footer from './components/layout/Footer';
import './styles/main.scss';

const App = () => {

  useEffect(() => {
    const token = getToken();

    if (token) setHeaderToken(token);

    store.dispatch(loadUser());

    // If user logs out with multiple tabs open, this logs them out of all tabs
    const logoutListener = () => {
      try {
        // Grab token from local storage
        const token = getToken();
        // If no token and user is logged in, log them out
        if ((!token || token === 'null') && store.getState().auth.isLoggedIn) {
          store.dispatch(logout());
        }

      } catch (err) {
        store.dispatch(logout());
      }
    }
    window.addEventListener('storage', logoutListener);
    return () => {
      window.removeEventListener('storage', logoutListener);
    }
  }, []);

  return <>
    <Provider store={store}>
      <Router>
        <div className="d-flex flex-column min-vh-100">
          <div>
            <Header />
          </div>
          <div className="flex-fill">
            <Main />
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </Router>
    </Provider>
  </>;
};

export default App;
