import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const Private = ({ children, ...rest }) => {
  const { isLoggedIn } = useSelector(state => state.auth);

  return <>
    <Route
      {...rest}
      render={({ location }) => isLoggedIn ? (
        children
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: location } }} />
      )}
    />
  </>;
};

Private.propTypes = {
  children: PropTypes.node.isRequired
};

export default Private;
