import React, { useEffect } from 'react';
import { Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import streakscreenshot from '../../images/home/streakscreenshot.png';
import entryscreenshot from '../../images/home/entry-screenshot.png';
import './styles/home.scss';
import { getNotebook } from '../../state/actions/notebook';

const Home = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, isPaid } = useSelector(store => store.auth);
  const { notebook } = useSelector(store => store.notebook);

  useEffect(() => { dispatch(getNotebook('default')) }, []);

  let ctaLink = '/register';
  if (isLoggedIn) {
    if (isPaid) {
      if (notebook) {
        ctaLink = '/entries';
      } else {
        ctaLink = '/goal';
      }
    } else {
      ctaLink = '/billing';
    }
  }

  return <>
    <h1 className="text-center">
      <span className="theme-color-one-dark"><b>The goal tracker for writers</b></span>{' '}
    </h1>
    <h3 className="text-center mb-4">
      <span className="theme-color-one"><b>Set a word count. Set the number of days per week. Write.</b></span>
    </h3>
    <div className="container mb-4">
      <div className="row justify-content-start mb-4 align-items-center">
        <div className="col-6">
          <Image src={entryscreenshot} alt="Streak" thumbnail />
        </div>
        <div className="col-6">
          <h4>
            <span className="theme-color-one-dark">
              Write daily and hit your word counts. We&apos;ll autosave your progress as you type.
            </span>
          </h4>
        </div>
      </div>
      <div className="row justify-content-end align-items-center">
      <div className="col-6">
          <h4>
            <span className="theme-color-one-dark">
              Motivate yourself to write more by seeing your current streak. We&apos;ll keep track of how many days you&apos;ve been writing.
            </span>
          </h4>
        </div>
        <div className="col-6">
          <Image src={streakscreenshot} alt="Streak" thumbnail />
        </div>
      </div>
    </div>
    <h3 className="mb-1">
      <span className="theme-color-one"><b>Created by writers for writers</b></span>
    </h3>
    <h4>
      <span className="theme-color-one-dark">
        This is a passion project by coders who love to write. Tracking streaks over time motivates us and we think it will help you too.
      </span>
    </h4>
    <div className="text-center">
      <Link to={ctaLink}>
        <Button className="mt-4" variant="primary" size="lg">
          Get Started Now!
        </Button>
      </Link>
    </div>
  </>;
};

export default Home;
