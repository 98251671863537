export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_PASSWORD_RESET_TOKEN_CHECKED = 'AUTH_PASSWORD_RESET_TOKEN_CHECKED';
export const AUTH_PASSWORD_UPDATE_SUCCESS = 'AUTH_PASSWORD_UPDATE_SUCCESS';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_REGISTER_ERROR = 'AUTH_REGISTER_ERROR';
export const AUTH_USER_LOAD = 'AUTH_USER_LOAD';
export const AUTH_USER_UPDATE = 'AUTH_USER_UPDATE';
export const AUTH_USER_UPDATE_ERROR = 'AUTH_USER_UPDATE_ERROR';
export const ENTRY_CREATE = 'ENTRY_CREATE';
export const ENTRY_DELETE = 'ENTRY_DELETE';
export const ENTRY_ERROR = 'ENTRY_ERROR';
export const ENTRY_LOAD = 'ENTRY_LOAD';
export const ENTRY_LOAD_ALL = 'ENTRY_LOAD_ALL';
export const ENTRY_UPDATE = 'ENTRY_UPDATE';
export const ENTRY_UPDATE_NOTIFY = 'ENTRY_UPDATE_NOTIFY';
export const GLOBAL_ALERT_CREATE = 'GLOBAL_ALERT_CREATE';
export const GLOBAL_ALERT_DELETE = 'GLOBAL_ALERT_DELETE';
export const NOTEBOOK_CREATE = 'NOTEBOOK_CREATE';
export const NOTEBOOK_DELETE = 'NOTEBOOK_DELETE';
export const NOTEBOOK_ERROR = 'NOTEBOOK_ERROR';
export const NOTEBOOK_LOAD = 'NOTEBOOK_LOAD';
export const NOTEBOOK_LOAD_ALL = 'NOTEBOOK_LOAD_ALL';
export const NOTEBOOK_UPDATE = 'NOTEBOOK_UPDATE';
