import React, { useState } from 'react';
import { requester } from '../../utils/requester';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const ManageBilling = () => {
  const [ disabled, setDisabled ] = useState(false);

  const handleOnClickManageBilling = async (e) => {
    e.preventDefault();
    setDisabled(true);

    try {
      const res = await requester.post('/api/purchases/manage');

      window.location = res.data.redirectUrl;
    } catch (err) {
      console.log(err);
    }
  };

  return <>
    <Button onClick={e => handleOnClickManageBilling(e)} disabled={disabled} variant="primary">
      Manage Billing <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
    </Button>
  </>;
};

export default ManageBilling;
