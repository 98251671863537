import React from 'react';
import Navigation from './Navigation';

const Header = () => (
  <header className="ws-header">
    <Navigation />
  </header>
);

export default Header;
