import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const { REACT_APP_RECAPTCHA_SITE_KEY: reCaptchaKey } = process.env;
ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={reCaptchaKey}
    scriptProps={{
      async: true, // optional, default to false,
      defer: true, // optional, default to false
    }}
  >
    <App />
  </GoogleReCaptchaProvider>, document.getElementById('root'));
