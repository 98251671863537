import { faClock, faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { resetPassword, updateUser } from '../../state/actions/auth';
import ManageBilling from '../billing/ManageBilling';
import { WSFormLabel, WSFormSubmitButton } from '../layout/Utils';
import timezones from './data/timezones';

const Profile = () => {
  const dispatch = useDispatch();
  const { isStripePaid, email, timezone } = useSelector(state => state.auth);
  const profileSchema = yup.object({
    email: yup.string().email().required('Email is required'),
    timezone: yup.string().oneOf(timezones).required('Timezone required')
  });

  const handleOnSubmit = (values, { setStatus }) => {
    dispatch(updateUser(values));
    setStatus({ sent: true, msg: 'User information saved' });
    setTimeout(() => setStatus({ sent: false, msg: '' }), 2000);
  };

  const handleOnSubmitPassword = (values) => {
    dispatch(resetPassword(
      values.password,
      { originalPassword: values.originalPassword },
    ));
  };

  return <>
    {isStripePaid && <>
      <div className="mb-5">
        <h1>Billing</h1>
        <ManageBilling />
      </div>
    </>}

    <div className="mb-5">
      <h1>Profile</h1>

      <Formik
        initialValues={{ email, timezone }}
        validationSchema={profileSchema}
        validateOnChange={false}
        onSubmit={handleOnSubmit}
      >
        {({ values, errors, handleChange, handleSubmit, status }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Row>
              <Form.Group as={Col} xs={12} md={6} lg={4} xl={3} className="mb-4">
                <WSFormLabel>
                  <FontAwesomeIcon icon={faEnvelope} /> Email
                </WSFormLabel>
                <Form.Control type="text" placeholder="Email" name="email" value={values.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.email}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} lg={4} xl={3} className="mb-4">
                <WSFormLabel>
                  <FontAwesomeIcon icon={faClock} /> Timezone
                </WSFormLabel>
                <Form.Control as={Form.Select} name="timezone" value={values.timezone} onChange={handleChange}>
                  {timezones.map(t => (
                    <option key={t} value={t}>{t}</option>
                  ))}
                </Form.Control>
                {errors.timezone && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.timezone}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <Form.Group>
              <WSFormSubmitButton>
                Update
              </WSFormSubmitButton>
            </Form.Group>

            {status && status.msg && (
              <Alert variant="success">{status.msg}</Alert>
            )}
          </Form>
        )}
      </Formik>
    </div>
    <div className='mb-5'>
      <h2 >Change Password</h2>
      <Formik
        initialValues={{ originalPassword: '', password: '', passwordConfirm: '' }}
        validationSchema={yup.object({
          originalPassword: yup.string().required('Original password is required'),
          password: yup.string().required('New password is required'),
          passwordConfirm: yup.string().oneOf([ yup.ref('password'), null ], 'Passwords must match')
        })}
        validateOnChange={false}
        onSubmit={handleOnSubmitPassword}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Row>
              <Form.Group as={Col} xs={12} md={6} lg={4} xl={3} className="mb-4">
                <WSFormLabel>
                  <FontAwesomeIcon icon={faLock} /> Original Password
                </WSFormLabel>
                <Form.Control type="password" placeholder="Original Password" name="originalPassword" value={values.originalPassword}
                  onChange={handleChange}
                />
                {errors.originalPassword && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.originalPassword}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} lg={4} xl={3} className="mb-4">
                <WSFormLabel>
                  <FontAwesomeIcon icon={faLock} /> New Password
                </WSFormLabel>
                <Form.Control type="password" placeholder="New Password" name="password" value={values.password}
                  onChange={handleChange}
                />
                {errors.password && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.password}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} lg={4} xl={3} className="mb-4">
                <WSFormLabel>
                  <FontAwesomeIcon icon={faLock} /> Repeat New Password
                </WSFormLabel>
                <Form.Control type="password" placeholder="Repeat New Password" name="passwordConfirm" value={values.passwordConfirm}
                  onChange={handleChange}
                />
                {errors.passwordConfirm && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.passwordConfirm}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group>
                <WSFormSubmitButton>
                  Change Password
                </WSFormSubmitButton>
              </Form.Group>
            </Row>
          </Form>
        )}
      </Formik>
    </div>

  </>;
};

export default Profile;
