import axios from 'axios';

export const requester = axios.create({
  baseURL: process.env.REACT_APP_WS_URL_API,
  headers: { 'Content-Type': 'application/json' }
});

export const setHeaderToken = token => {
  requester.defaults.headers.common['Authorization'] = token;
};

export const clearHeaderToken = () => {
  delete requester.defaults.headers.common['Authorization'];
};

// NTH: Check if token can be refreshed, otherwise log out the user
// requester.interceptors.response.use(
//   res => res,
//   err => {
//     if (err.response.status === 401) store.dispatch(logout());

//     return Promise.reject(err);
//   }
// );
