import React from 'react';
import { useSelector } from 'react-redux';
import Loading from './Loading';
import GlobalAlerts from './GlobalAlerts';
import Container from 'react-bootstrap/Container';
import Routes from '../routes/Routes';

const Main = () => {
  const { loading: loadingAuth } = useSelector(state => state.auth);

  return loadingAuth ? <Loading /> : <>
    <main role="main">
      <GlobalAlerts />
      <Container className="py-5">
        <Routes />
      </Container>
    </main>
  </>;
};

export default Main;
