import {
  NOTEBOOK_CREATE,
  NOTEBOOK_DELETE,
  NOTEBOOK_ERROR,
  NOTEBOOK_LOAD,
  NOTEBOOK_LOAD_ALL,
  NOTEBOOK_UPDATE
} from '../types';

const preloadedState = {
  loading: true,
  notebooks: [],
  notebook: null
};

export default (state = preloadedState, action) => {
  const { type, payload } = action;

  switch (type) {
    case NOTEBOOK_LOAD_ALL:
      return {
        ...state,
        notebooks: payload,
        loading: false
      };

    case NOTEBOOK_LOAD:
      return {
        ...state,
        notebook: payload,
        loading: false
      };

    case NOTEBOOK_CREATE:
      return {
        ...state,
        notebooks: [ payload, ...state.notebooks ],
        loading: false
      };

    case NOTEBOOK_UPDATE:
      return {
        ...state,
        notebooks: state.notebooks.map(n => n.id === payload.id ? { ...n, ...payload } : n),
        loading: false
      };

    case NOTEBOOK_DELETE:
      return {
        ...state,
        notebooks: state.notebooks.filter(n => n.id !== payload),
        loading: false
      };

    case NOTEBOOK_ERROR:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};
