import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { gtag } from '../../hooks/useGoogleAnalytics';
import { sendEvent } from '../../hooks/useUniversalAnalytics';
import { login } from '../../state/actions/auth';
import { Formik } from 'formik';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { WSFormLabel, WSFormSubmitButton } from '../layout/Utils';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

const Login = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(state => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };
  const loginSchema = yup.object({
    email: yup.string().email().required('Email is required'),
    password: yup.string().required('Password is required')
  });
  let recaptchaToken = null;

  useEffect(() => {
    if (isLoggedIn) history.replace(from);
  }, [ isLoggedIn ]);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    recaptchaToken = await executeRecaptcha('login');
  }, [ executeRecaptcha ]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [ handleReCaptchaVerify ]);

  const handleOnSubmit = async values => {
    if (!recaptchaToken) {
      recaptchaToken = await executeRecaptcha('login');
    }
    dispatch(login(values, recaptchaToken));
    gtag('event', 'login');
    sendEvent({ category: 'user', action: 'login' });
  };

  return <>
    <h1>Login</h1>

    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={loginSchema}
      validateOnChange={false}
      onSubmit={handleOnSubmit}
    >
      {({ values, errors, handleChange, handleSubmit, status }) => (
        <Form onSubmit={handleSubmit} noValidate>
          <Row>
            <Form.Group as={Col} xs={12} md={6} lg={4} xl={3} className="mb-4">
              <WSFormLabel>
                <FontAwesomeIcon icon={faEnvelope} /> Email
              </WSFormLabel>
              <Form.Control type="text" name="email" value={values.email} onChange={handleChange} />
              {errors.email && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} lg={4} xl={3} className="mb-4">
              <WSFormLabel>
                <FontAwesomeIcon icon={faAsterisk} /> Password
              </WSFormLabel>
              <Form.Control type="password" name="password" value={values.password} onChange={handleChange} />
              {errors.password && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Row>

          <Form.Group>
            <WSFormSubmitButton>
              Log In
            </WSFormSubmitButton>
          </Form.Group>

          {status && status.msg && (
            <Alert variant="success">{status.msg}</Alert>
          )}
        </Form>
      )}
    </Formik>

    <p className="mt-2">
      Don&#39;t have an account? <Link to="/register">Register here.</Link>
    </p>
    <p className="mt-2">
      Forgot your password? <Link to="/forgot-password">Reset it here.</Link>
    </p>

    <small className="mt-4">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</small>
  </>;
};

export default Login;
