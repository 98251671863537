import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGlobalAlert } from '../../state/actions/globalAlerts';
import { Alert, Container, Button } from 'react-bootstrap';

const GlobalAlerts = () => {
  const dispatch = useDispatch();
  const globalAlerts = useSelector(state => state.globalAlerts);

  if (globalAlerts !== null && globalAlerts.length > 0) {
    return globalAlerts.map(globalAlert => {
      const {
        id,
        title,
        message,
        variant
      } = globalAlert;

      return <>
        <Alert key={id} variant={variant} className="p-2">
          <Container>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                {title && (
                  <Alert.Heading className={message ? 'mb-2' : 'mb-0'}>
                    {title}
                  </Alert.Heading>
                )}
                {message && (
                  <p className="mb-0">
                    {message}
                  </p>
                )}
              </div>
              <div className="d-flex justify-content-end">
                <Button onClick={() => {dispatch(deleteGlobalAlert(id))}} variant={`outline-${variant}`}>
                  Dismiss
                </Button>
              </div>
            </div>
          </Container>
        </Alert>
      </>;
    });
  }

  return null;
};

export default GlobalAlerts;
