import React, { useRef, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import { useDispatch, useSelector } from 'react-redux';
import { checkSessionIsPaid } from '../../state/actions/auth';
import { createNotebook, getNotebook, updateNotebook } from '../../state/actions/notebook';
import Loading from '../layout/Loading';
import { Formik } from 'formik';
import * as yup from 'yup';
import { WSFormLabel, WSFormSubmitButton } from '../layout/Utils';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const Notebook = () => {
  const isInitRender = useRef(true);
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const {
    loading: loadingAuth,
    isPaid
  } = useSelector(state => state.auth);
  const {
    loading: loadingNotebook,
    notebook
  } = useSelector(state => state.notebook);
  const goalSchema = yup.object({
    word_goal: yup.number().required('Word count is required').positive().integer(),
    days_per_week_goal: yup.number().required('Days per week is required').integer().min(1).max(7)
  });

  useEffect(() => {
    const sessionId = query.get('session_id');

    if (!isPaid && sessionId) {
      if (isInitRender.current) {
        dispatch(checkSessionIsPaid(sessionId));
        isInitRender.current = false;
      } else {
        if (!loadingAuth && !isPaid) history.push('/goal');
      }
    }
  }, [ isInitRender.current, loadingAuth ]);

  useEffect(() => { dispatch(getNotebook('default')) }, []);

  const setInitialValues = () => {
    let word_goal = 50;
    let days_per_week_goal = 1;

    if (notebook) {
      word_goal = notebook.word_goal;
      days_per_week_goal = notebook.days_per_week_goal;
    }

    return { word_goal, days_per_week_goal };
  };

  const handleOnSubmit = values => {
    if (notebook) {
      dispatch(updateNotebook('default', values));
    } else {
      dispatch(createNotebook(values));
    }
  };

  return <>
    <h1>Goal</h1>

    {loadingNotebook ? <Loading /> : <>
      <Formik
        initialValues={setInitialValues()}
        validationSchema={goalSchema}
        validateOnChange={false}
        onSubmit={handleOnSubmit}
      >
        {({ values, errors, handleChange, handleSubmit, status }) => <>
          <Form onSubmit={handleSubmit} noValidate>
            <Row>
              <Form.Group as={Col} xs={12} md={6} lg={4} xl={3} className="mb-4">
                <WSFormLabel>
                  <FontAwesomeIcon icon={faFileAlt} /> Word Count
                </WSFormLabel>
                <Form.Control type="number" name="word_goal" value={values.word_goal} onChange={handleChange}/>
                {errors.word_goal && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.word_goal}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} lg={4} xl={3} className="mb-4">
                <WSFormLabel>
                  <FontAwesomeIcon icon={faCalendarAlt} /> Days per Week
                </WSFormLabel>
                <Form.Control type="number" name="days_per_week_goal" value={values.days_per_week_goal} onChange={handleChange}/>
                {errors.days_per_week_goal && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.days_per_week_goal}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>

            <Form.Group>
              <WSFormSubmitButton>
                {notebook ? 'Save' : 'Create'}
              </WSFormSubmitButton>
            </Form.Group>

            {status && status.msg && (
              <Alert variant="success">{status.msg}</Alert>
            )}
          </Form>
        </>}
      </Formik>
      {notebook && <>
        <Link to={'/entries'}>
          <Button variant="primary">
            Start writing
          </Button>
        </Link>
      </>}
    </>}
  </>;
};

export default Notebook;
