import { GLOBAL_ALERT_CREATE, GLOBAL_ALERT_DELETE } from '../types';
import { v4 as uuidv4 } from 'uuid';

export const createGlobalAlert = (title = '', message = '', variant = 'secondary', timeout = 5000) => dispatch => {
  const id = uuidv4();

  dispatch({ type: GLOBAL_ALERT_CREATE, payload: { id, title, message, variant } });

  if (timeout > 0) setTimeout(() => dispatch({ type: GLOBAL_ALERT_DELETE, payload: id }), timeout);
};

export const deleteGlobalAlert = globalAlertId => dispatch => {
  dispatch({ type: GLOBAL_ALERT_DELETE, payload: globalAlertId });
};
