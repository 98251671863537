import React, { useState } from 'react';
import { requester } from '../../utils/requester';
import { gtag } from '../../hooks/useGoogleAnalytics';
import { sendEvent } from '../../hooks/useUniversalAnalytics';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const PaymentOptions = () => {
  const {
    REACT_APP_STRIPE_MONTHLY_PRICE_ID: priceIdMonthly,
    REACT_APP_STRIPE_YEARLY_PRICE_ID: priceIdYearly
  } = process.env;
  const [ disabled, setDisabled ] = useState(false);

  const handleOnClickButton = async e => {
    e.preventDefault();
    setDisabled(true);

    const priceId = e.target.getAttribute('data-price-id');

    if (priceId === priceIdMonthly) {
      gtag('event', 'purchase', { subscription: 'monthly' });
      sendEvent({ category: 'payment', action: 'monthly' });
    } else if (priceId === priceIdYearly) {
      gtag('event', 'purchase', { subscription: 'yearly' });
      sendEvent({ category: 'payment', action: 'yearly' });
    }

    try {
      const res = await requester.post('/api/purchases/subscribe', { priceId });

      window.location = res.data.redirectUrl;
    } catch (err) {
      console.log(err);
    }
  };

  return <>
    <div className="row">
      <div className="col-12">
        <h3>Payment Options</h3>
        <h5>Start a free 7-day trial.</h5>
        <h5>After that, WriterStreak is $5/mo or $48/yr (Save 20%!).</h5>
      </div>
    </div>
    <div className="row mb-4">
      <div className="col-12">
        <div>
          <Button onClick={e => handleOnClickButton(e)} data-price-id={priceIdMonthly} disabled={disabled}
            variant="primary" className="me-3"
          >
            Pay Monthly <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
          </Button>
          <Button onClick={e => handleOnClickButton(e)} data-price-id={priceIdYearly} disabled={disabled}
            variant="primary"
          >
            Pay Yearly and Save 20% <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
          </Button>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <hr />
        <h6>
          Whether you&apos;re trying to build a journaling habit or write the next great novel, having a
          word goal is essential. 
        </h6>
        <h6>
          Anne Rice, Ernest Hemingway, Stephen King, and countless 
          other successful authors use word goals in order to crank out their books.
        </h6>
        <h6>
          Need inspiration? Check out <a target='_blank' rel='noreferrer' href='http://www.writerswrite.co.za/the-daily-word-counts-of-39-famous-authors-1/'>this excellent compilation</a> of famous authors and their word goals.
        </h6>
        <h6>
          On top of a word goal, streaks add to your writing success. Often called the &quot;Seinfeld Strategy&quot; because it&apos;s the strategy that Jerry Seinfeld attributes
          some of his success to, streaks are a method used to build consistency. As your streak gets higher
          and higher, you won&apos;t want to break the chain and start over so you&apos;ll be motivated to keep going.
        </h6>
        <h6>
          Give our free trial a try and see if it works for you.
        </h6>
      </div>
    </div>
  </>;
};

export default PaymentOptions;
