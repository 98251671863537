import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ManageBilling from './ManageBilling';
import PaymentOptions from './PaymentOptions';

const Billing = () => {
  const { isPaid, isStripePaid } = useSelector(state => state.auth);
  const greeting = isPaid ? 'Thank you for purchasing WriterStreak!' : 'Go Premium with a Free Trial!';
  const cta = isStripePaid ? <>
      <Link to="/goal">
        <button className="btn btn-primary">Continue to Creating a Goal</button>
      </Link>
    </>
    : 
    <>
      <ManageBilling />
    </>;
  return <>
    <h1>{greeting}</h1>
    {isPaid ? 
      cta : <>
        <PaymentOptions />
      </>
    }
  </>
};

export default Billing;
